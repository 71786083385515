import moment from 'moment'

export default {
  dashboardChart (data) {
    const monthCreateDoneLabel = monthCreateDoneLabelData(data.dateSelected)
    const result = {}
    const estimateData = estimateItem(data.estimateItem)
    const monthDoneData = monthDone(data.testerReportData, monthCreateDoneLabel)
    const monthCreateData = monthCreate(data.createdReportData, monthCreateDoneLabel)
    result.estimateData = estimateData
    result.monthDoneData = monthDoneData
    result.monthCreateData = monthCreateData
    return result
  },

  dateListGenerate (showMethod, startDate, endDate) {
    const dateList = []
    if (showMethod === 'Month') {
      let startMonthYears = moment(startDate, ['YYYY-MM-DD']).format('MM-YYYY')
      const endMonthYears = moment(endDate, ['YYYY-MM-DD']).format('MM-YYYY')
      while (startMonthYears !== endMonthYears) {
        dateList.push(startMonthYears)
        startMonthYears = moment(startMonthYears, 'MM-YYYY').add(1, 'months').format('MM-YYYY')
      }
      dateList.push(endMonthYears)
    }
    if (showMethod === 'Day') {
      let startMonthDayYears = moment(startDate).format('YYYY-MM-DD')
      const endMonthDayYears = moment(endDate).format('YYYY-MM-DD')
      while (startMonthDayYears !== endMonthDayYears) {
        dateList.push(startMonthDayYears)
        startMonthDayYears = moment(startMonthDayYears).add(1, 'days').format('YYYY-MM-DD')
      }
      dateList.push(endMonthDayYears)
    }
    return dateList
  },
  orderDateToMY (orderList, name) {
    const result = []
    for (const index in orderList) {
      const newDate = moment(orderList[index][name], ['YYYY-MM-DD']).format('MM-YYYY')
      orderList[index][name] = newDate
      result.push(orderList[index])
    }
    return result
  },
  orderDateToY (orderList, name) {
    const result = []
    for (const index in orderList) {
      const newDate = moment(orderList[index][name], ['YYYY-MM-DD']).format('YYYY')
      orderList[index][name] = newDate
      result.push(orderList[index])
    }
    return result
  },
  randomTransparentColor () {
    const r = getRandomInt(0, 255)
    const g = getRandomInt(0, 255)
    const b = getRandomInt(0, 255)
    const color = 'rgb(' + r + ',' + g + ',' + b + ',0.5)'
    return color
  },
}

function getRandomInt (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function monthCreateDoneLabelData (date) {
  const result = []
  let dateForm = moment(date.dateFrom, 'YYYY-MM-DD').format('YYYY-MM')
  const dateTo = moment(date.dateTo, 'YYYY-MM-DD').format('YYYY-MM')
  while (dateForm <= dateTo) {
    result.push(dateForm)
    dateForm = moment(dateForm, 'YYYY-MM').add(1, 'months').format('YYYY-MM')
  }
  return result
}

function monthCreate (monthCreateData, label) {
  const result = {}
  const labelData = []
  const seriesData = []
  label.forEach((item, index) => {
    const newDate = moment(item, 'YYYY-MM').format('MMM')
    labelData.push(newDate)
    const dateValue = monthCreateData.find(obj => obj.receiptDate === item)
    dateValue ? seriesData.push(dateValue.totalCreated) : seriesData.push('0')
  })
  result.labelData = labelData
  result.seriesData = seriesData
  return result
}

function monthDone (monthDoneData, label) {
  const result = {}
  const labelData = []
  const seriesData = []
  label.forEach((item, index) => {
    const newDate = moment(item, 'YYYY-MM').format('MMM')
    labelData.push(newDate)
    const dateValue = monthDoneData.find(obj => obj.date === item)
    dateValue ? seriesData.push(dateValue.testedQuantity) : seriesData.push('0')
  })
  result.labelData = labelData
  result.seriesData = seriesData
  return result
}

function estimateItem (estimateItemData) {
  const result = {}
  const labelData = []
  const seriesData = []
  estimateItemData.forEach((item, index) => {
    const newDate = moment(item.receiptDate, 'YYYY-MM-DD').format('dd')
    labelData.push(newDate)
    seriesData.push(item.requestDueItems)
  })
  result.labelData = labelData
  result.seriesData = seriesData
  return result
}
